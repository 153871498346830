import {Endpoints} from "~/Endpoints"
import Dispatcher from "~/flux/Dispatcher"
import * as HttpClient from "~/lib/HttpClient"
import type {Space} from "~/records/SpaceRecord"
import type {SpaceRole} from "~/records/SpaceRoleRecord"
import ChannelStore from "~/stores/ChannelStore"

export const create = async (params: Pick<Space, "name"> & {icon?: string | null}) => {
  const {body} = await HttpClient.post<Space>({url: Endpoints.SPACES, body: params})
  return body
}

export const update = async (spaceId: string, params: Pick<Space, "name" | "description"> & {icon?: string | null}) => {
  const {body} = await HttpClient.patch<Space>({url: Endpoints.SPACE(spaceId), body: params})
  return body
}

export const sortChannels = async (spaceId: string, positions: Array<string>) => {
  const channelPositionsCopy = ChannelStore.getSpaceChannels(spaceId)
    .sort((a, b) => a.position - b.position)
    .map((channel) => channel.id)
  try {
    Dispatcher.dispatch({type: "CHANNEL_POSITIONS_UPDATE", spaceId, positions})
    await HttpClient.patch({url: Endpoints.SPACE_CHANNELS(spaceId), body: positions})
  } catch (error) {
    Dispatcher.dispatch({type: "CHANNEL_POSITIONS_UPDATE", spaceId, positions: channelPositionsCopy})
    throw error
  }
}

export const updateVanityURL = async (spaceId: string, code: string | null) => {
  const {body} = await HttpClient.patch<{code: string}>({url: Endpoints.SPACE_VANITY_URL(spaceId), body: {code}})
  return body.code
}

export const createRole = async (spaceId: string, name: string) => {
  await HttpClient.post({url: Endpoints.SPACE_ROLES(spaceId), body: {name}})
}

export const updateRole = async (spaceId: string, roleId: string, patch: Partial<SpaceRole>) => {
  await HttpClient.patch({url: Endpoints.SPACE_ROLE(spaceId, roleId), body: patch})
}

export const deleteRole = async (spaceId: string, roleId: string) => {
  await HttpClient.del({url: Endpoints.SPACE_ROLE(spaceId, roleId)})
}

export const sortRoles = async (spaceId: string, positions: Array<string>) => {
  await HttpClient.patch({url: Endpoints.SPACE_ROLES(spaceId), body: positions})
}

export const remove = async (spaceId: string, password: string) => {
  await HttpClient.post({url: Endpoints.SPACE_DELETE(spaceId), body: {password}})
}

export const leave = async (spaceId: string) => {
  await HttpClient.del({url: Endpoints.USER_SPACES(spaceId)})
}
